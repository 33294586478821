import {
	defaultError,
	getDeleteHeaderWithCredentials,
	getGetHeaderWithCredentials,
	getPatchHeaderWithCredentials,
	getPostHeaderWithCredentials,
} from "api/config";
import {
	URL_DELETE_CHALLENGE_TENDER,
	URL_DELETE_CHALLENGE_TENDER_TALENT,
	URL_DELETE_REMOVE_CONSULTANT_FROM_TENDER,
	URL_GET_CHALLENGE_TENDER,
	URL_GET_CONSULTANT_TENDER,
	URL_GET_TALENTS_BY_ID_TENDER,
	URL_GET_TENDERS_BY_CHALLENGE,
	URL_GET_TENDERS_BY_LOGGED_USER,
	URL_PATCH_CHALLENGE_TENDER,
	URL_PATCH_CHALLENGE_TENDER_TALENT,
	URL_POST_ADD_CONSULTANT_TO_TENDER,
	URL_POST_CHALLENGE_TENDER,
	URL_POST_CHALLENGE_TENDER_TALENT,
} from "./urls";

export function getChallengeTenderAPI(idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_GET_CHALLENGE_TENDER(idTender), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getChallengeTendersByChallengeAPI(idChallenge: number, accessToken: string) {
	return function () {
		return fetch(URL_GET_TENDERS_BY_CHALLENGE(idChallenge), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getTendersByLoggedUserAPI(accessToken: string) {
	return function () {
		return fetch(URL_GET_TENDERS_BY_LOGGED_USER(), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getConsultantTenderAPI(idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_GET_CONSULTANT_TENDER(idTender), getGetHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function getTalentsByTenderIDAPI(idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_GET_TALENTS_BY_ID_TENDER(idTender), getGetHeaderWithCredentials(accessToken))
			.then((response) => {
				return response.json();
			})
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postChallengeTenderAPI(data: any, accessToken: string) {
	return function () {
		return fetch(URL_POST_CHALLENGE_TENDER, getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postChallengeTenderTalentAPI(data: any, idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_POST_CHALLENGE_TENDER_TALENT(idTender), getPostHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function postAddConsultantToTenderAPI(idTender: number, consultantSlug: string, accessToken: string) {
	return function () {
		return fetch(
			URL_POST_ADD_CONSULTANT_TO_TENDER(idTender, consultantSlug),
			getPostHeaderWithCredentials({}, accessToken),
		)
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchChallengeTenderAPI(data: any, idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_CHALLENGE_TENDER(idTender), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function patchChallengeTenderTalentAPI(data: any, idTenderTalent: number, accessToken: string) {
	return function () {
		return fetch(URL_PATCH_CHALLENGE_TENDER_TALENT(idTenderTalent), getPatchHeaderWithCredentials(data, accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function deleteChallengeTenderAPI(idTender: number, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_CHALLENGE_TENDER(idTender), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function deleteChallengeTenderTalentAPI(idTenderTalent: number, accessToken: string) {
	return function () {
		return fetch(URL_DELETE_CHALLENGE_TENDER_TALENT(idTenderTalent), getDeleteHeaderWithCredentials(accessToken))
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}

export function deleteRemoveConsultantFromTenderAPI(idTender: number, consultantId: number, accessToken: string) {
	return function () {
		return fetch(
			URL_DELETE_REMOVE_CONSULTANT_FROM_TENDER(idTender, consultantId),
			getDeleteHeaderWithCredentials(accessToken),
		)
			.then((response) => response.json())
			.catch((error) => {
				if (error.name === "SyntaxError") {
					return {};
				}

				return defaultError();
			});
	};
}
