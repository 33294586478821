import { CategoryType } from "api/CategoriesAPI/urls";
import { DateUtils } from "@utils";
import { MicroBusinessProfileTypes } from "constants/Profiles";

import { StringUtils } from "utils/StringUtils";

import BusinessProfileModel from "../business-profiles/BusinessProfile";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import CategoryModel from "models/categories/Category";
import ElearningModel from "models/elearnings/Elearning";
import TranslationsServiceSingleton from "services/translations/TranslationsService";
import { PageDirectoryUtils } from "@utils";
import {Locales} from "@libs";

export enum ChallengeType {
	PRODUCT = "PRODUCT",
	SERVICE = "SERVICE",
}

export enum ChallengeSituation {
	DENIED = "DENIED",
	PENDING = "PENDING",
	ACCEPTED = "ACCEPTED",
	FINISHED = "FINISHED",
}

export enum ChallengeStatus {
	DRAFT = "DRAFT",
	PUBLISHED = "PUBLISHED",
}

export interface Challenge {
	id: number;
	slug: string;
	profileSlug: string;
	adminName: string;
	adminEmail: string;
	adminProfileType: MicroBusinessProfileTypes;
	title: string;
	startDate: number;
	endDate: number;
	situation: ChallengeSituation;
	status: ChallengeStatus;
	type: ChallengeType;
	sectors: CategoryModel[];
	topic: CategoryModel;
	milestones: string[];
	description: string;
	descriptionRequirements: string;
	descriptionDeliveryFormat: string;
	descriptionNormative: string;
	descriptionResponsibleForEvaluating: string;
	descriptionPrize: string;
	others: boolean;
	elearnings: ElearningModel[];
	consultants: BusinessProfileModel[];
	published: boolean;
	isMine: boolean;
	isInvited: boolean;
	isVisitorInvited: boolean;
}

class ChallengeModel implements Challenge {
	id: number;
	slug: string;
	profileSlug: string;
	adminName: string;
	adminEmail: string;
	adminProfileType: MicroBusinessProfileTypes;
	title: string;
	startDate: number;
	endDate: number;
	situation: ChallengeSituation;
	status: ChallengeStatus;
	type: ChallengeType;
	sectors: CategoryModel[];
	topic: CategoryModel;
	milestones: string[];
	description: string;
	descriptionRequirements: string;
	descriptionDeliveryFormat: string;
	descriptionNormative: string;
	descriptionResponsibleForEvaluating: string;
	descriptionPrize: string;
	others: boolean;
	elearnings: ElearningModel[];
	consultants: BusinessProfileModel[];
	published: boolean;
	isMine: boolean;
	// This means for the invited business profile not for the students/employee
	isInvited: boolean;
	isVisitorInvited: boolean;

	constructor(
		id: number,
		slug: string,
		profileSlug: string,
		adminName: string,
		adminEmail: string,
		adminProfileType: MicroBusinessProfileTypes,
		title: string,
		startDate: number,
		endDate: number,
		situation: ChallengeSituation,
		status: ChallengeStatus,
		type: ChallengeType,
		sectors: CategoryModel[],
		topic: CategoryModel,
		milestones: string[],
		description: string,
		descriptionRequirements: string,
		descriptionDeliveryFormat: string,
		descriptionNormative: string,
		descriptionResponsibleForEvaluating: string,
		descriptionPrize: string,
		others: boolean,
		elearnings: ElearningModel[],
		consultants: BusinessProfileModel[],
		published: boolean,
		isMine: boolean,
		isInvited: boolean,
		isVisitorInvited: boolean,
	) {
		this.id = id;
		this.slug = slug;
		this.profileSlug = profileSlug;
		this.adminName = adminName;
		this.adminEmail = adminEmail;
		this.adminProfileType = adminProfileType;
		this.title = StringUtils.capitalize(title);
		this.startDate = startDate;
		this.endDate = endDate;
		this.situation = situation;
		this.status = status;
		this.type = type;
		this.sectors = sectors;
		this.topic = topic;
		this.milestones = milestones;
		this.description = description;
		this.descriptionRequirements = descriptionRequirements;
		this.descriptionDeliveryFormat = descriptionDeliveryFormat;
		this.descriptionNormative = descriptionNormative;
		this.descriptionResponsibleForEvaluating = descriptionResponsibleForEvaluating;
		this.descriptionPrize = descriptionPrize;
		this.others = others;
		this.elearnings = elearnings;
		this.consultants = consultants;
		this.published = published;
		this.isMine = isMine;
		this.isInvited = isInvited;
		this.isVisitorInvited = isVisitorInvited;
	}

	haveIPrivilegesToRead = () => {
		return this.isMine || this.isInvited || this.isVisitorInvited;
	};

	getStartDateUIText = () => {
		return DateUtils.getDefaultFormatFromEPOCH(this.startDate);
	};

	getEndDateUIText = () => {
		return DateUtils.getDefaultFormatFromEPOCH(this.endDate);
	};

	getInternalURL = (locale: Locales) => {
		return PageDirectoryUtils.ROUTE_CHALLENGUE_WITH_LOCALE(locale) + `/${this.slug}`;
	};

	exportToAPIRequest = () => {
		return {
			profile_slug: this.profileSlug,
			admin_name: this.adminName,
			admin_email: this.adminEmail,
			title: this.title,
			start_date: DateUtils.getTimeAPIFormat(this.startDate),
			end_date: DateUtils.getTimeAPIFormat(this.endDate),
			situation: this.situation,
			slug: StringUtils.convertStringToCorrectSlug(this.title),
			status: this.status,
			type: this.type,
			sectors: [] as any[],
			topics: this.topic.id ? [this.topic.id] : [],
			milestones: this.milestones,
			description: this.description,
			requirements: this.descriptionRequirements,
			delivery_format: this.descriptionDeliveryFormat,
			normative: this.descriptionNormative,
			description_evaluating: this.descriptionResponsibleForEvaluating,
			prize: this.descriptionPrize,
			others: this.others,
			elearnings: this.elearnings.map((elearning) => elearning.id),
			consultants: this.consultants.map((consultants) => consultants.info.id),
		};
	};

	static generateFromAPI(data: any = {}) {
		const { market: marketsTranslations, topic: topicsTranslations } =
			TranslationsServiceSingleton.getFeatureTranslations(["market", "topic"]);
		const categoriesList = CategoriesServiceSingleton.getList();
		const sectorsCategories = categoriesList.filterByIdsAndCategoryType(
			data.sectors.map((item: any) => item.id),
			CategoryType.SECTOR,
		) as CategoryModel[];
		sectorsCategories.forEach((category) => {
			category.setText(marketsTranslations.items[category.key]);
		});
		const elearnings = data.elearnings
			? data.elearnings.map((elearning: any) => ElearningModel.generateMiniFromAPI(elearning))
			: [];
		const consultants = data.consultants
			? data.consultants.map((consultant: any) =>
					BusinessProfileModel.generateMiniFromAPI({
						...consultant,
						type: MicroBusinessProfileTypes.ADVISER,
					}),
			  )
			: [];
		const topic =
			data.topics && data.topics.length > 0
				? CategoryModel.generateFromAPI(data.topics[0])
				: CategoryModel.generateEmpty();
		const startDate = DateUtils.getEpochFromAPIFormat(data.start_date);
		const endDate = DateUtils.getEpochFromAPIFormat(data.end_date);
		let situation: ChallengeSituation = data.situation;

		if (endDate < Date.now()) {
			situation = ChallengeSituation.FINISHED;
		}

		if (topic.key) {
			topic.setText(topicsTranslations.items[topic.key]);
		}

		return new ChallengeModel(
			data.id,
			data.slug + "-" + data.id,
			data.profile.slug,
			data.admin_name,
			data.admin_email,
			data.profile.type as MicroBusinessProfileTypes,
			data.title,
			startDate,
			endDate,
			situation,
			data.status,
			data.type,
			sectorsCategories,
			topic,
			Array.isArray(data.milestones) ? data.milestones : [],
			data.description || "",
			data.requirements || "",
			data.delivery_format || "",
			data.normative || "",
			data.description_evaluating || "",
			data.price || "",
			data.others,
			elearnings,
			consultants,
			false,
			data.is_mine,
			data.is_invited,
			data.is_user_invited,
		);
	}

	static generateEmpty() {
		return new ChallengeModel(
			0,
			"",
			"",
			"",
			"",
			MicroBusinessProfileTypes.UNIVERSITY,
			"",
			DateUtils.getCurrentTime(),
			DateUtils.getCurrentTime(),
			ChallengeSituation.PENDING,
			ChallengeStatus.DRAFT,
			ChallengeType.PRODUCT,
			[],
			CategoryModel.generateEmpty(),
			[],
			"",
			"",
			"",
			"",
			"",
			"",
			false,
			[],
			[],
			false,
			false,
			false,
			false,
		);
	}

	static generateFromObject(challengeObject: Challenge) {
		return new ChallengeModel(
			challengeObject.id,
			challengeObject.slug,
			challengeObject.profileSlug,
			challengeObject.adminName,
			challengeObject.adminEmail,
			challengeObject.adminProfileType,
			challengeObject.title,
			challengeObject.startDate,
			challengeObject.endDate,
			challengeObject.situation,
			challengeObject.status,
			challengeObject.type,
			challengeObject.sectors,
			challengeObject.topic,
			challengeObject.milestones,
			challengeObject.description,
			challengeObject.descriptionRequirements,
			challengeObject.descriptionDeliveryFormat,
			challengeObject.descriptionNormative,
			challengeObject.descriptionResponsibleForEvaluating,
			challengeObject.descriptionPrize,
			challengeObject.others,
			challengeObject.elearnings
				? challengeObject.elearnings.map((elearning) => ElearningModel.generateFromObject(elearning))
				: [],
			challengeObject.consultants
				? challengeObject.consultants.map((consultant) => BusinessProfileModel.generateFromObject(consultant))
				: [],
			challengeObject.published,
			challengeObject.isMine,
			challengeObject.isInvited,
			challengeObject.isVisitorInvited,
		);
	}
}

export default ChallengeModel;
