import { PROD_URL } from "api/config";

// GETS
const URL_GET_CHALLENGE_TENDER = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}`;
const URL_GET_TENDERS_BY_CHALLENGE = (idChallenge: number) => `${PROD_URL}/challenges/${idChallenge}/challenge-tenders`;
const URL_GET_TENDERS_BY_LOGGED_USER = () => `${PROD_URL}/challenge-tenders/user`;
const URL_GET_TALENTS_BY_ID_TENDER = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}/talents`;
const URL_GET_CONSULTANT_TENDER = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}/consultant`;
// POSTS
const URL_POST_CHALLENGE_TENDER = `${PROD_URL}/challenge-tenders`;
const URL_POST_CHALLENGE_TENDER_TALENT = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}/talent`;
const URL_POST_ADD_CONSULTANT_TO_TENDER = (idTender: number, consultantSlug: string) =>
	`${PROD_URL}/challenge-tenders/${idTender}/consultant/${consultantSlug}`;
// PATCHS
const URL_PATCH_CHALLENGE_TENDER = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}`;
const URL_PATCH_CHALLENGE_TENDER_TALENT = (tenderTalentId: number) =>
	`${PROD_URL}/challenge-tenders/${tenderTalentId}/talent`;
// DELETES
const URL_DELETE_CHALLENGE_TENDER = (idTender: number) => `${PROD_URL}/challenge-tenders/${idTender}`;
const URL_DELETE_CHALLENGE_TENDER_TALENT = (tenderTalentId: number) =>
	`${PROD_URL}/challenge-tenders/${tenderTalentId}/talent`;
const URL_DELETE_REMOVE_CONSULTANT_FROM_TENDER = (idTender: number, consultantId: number) =>
	` ${PROD_URL}/challenge-tenders/${idTender}/consultant/${consultantId}`;

export {
	URL_POST_CHALLENGE_TENDER,
	URL_POST_CHALLENGE_TENDER_TALENT,
	URL_POST_ADD_CONSULTANT_TO_TENDER,
	URL_PATCH_CHALLENGE_TENDER,
	URL_PATCH_CHALLENGE_TENDER_TALENT,
	URL_DELETE_CHALLENGE_TENDER,
	URL_DELETE_CHALLENGE_TENDER_TALENT,
	URL_DELETE_REMOVE_CONSULTANT_FROM_TENDER,
	URL_GET_CHALLENGE_TENDER,
	URL_GET_TALENTS_BY_ID_TENDER,
	URL_GET_TENDERS_BY_CHALLENGE,
	URL_GET_TENDERS_BY_LOGGED_USER,
	URL_GET_CONSULTANT_TENDER,
};
