import { StringUtils } from "utils/StringUtils";
import { Image } from "elements/HeroImage/typing";
import { getSocial, URL_VINGLET_IMAGE } from "constants/URLs";
import ProfileModel from "models/profiles/Profile";
import ChallengeModel from "models/challenges/Challenge";
import TalentModel from "models/talents/Talent";
import { PageDirectoryUtils } from "@utils";
import {Locales} from "@libs";

const DEFAULT_IMAGE = "/default-image.png";

export enum ChallengeTenderSocialType {
	GOOGLE_MEET = "GOOGLE_MEET",
	MICROSOFT_TEAM = "MICROSOFT_TEAM",
	ZOOM = "ZOOM",
	TRELLO = "TRELLO",
}

interface ChallengeTenderSocial {
	[ChallengeTenderSocialType.GOOGLE_MEET]: string;
	[ChallengeTenderSocialType.MICROSOFT_TEAM]: string;
	[ChallengeTenderSocialType.ZOOM]: string;
	[ChallengeTenderSocialType.TRELLO]: string;
}

export interface ChallengeTender {
	id: number;
	challengeID: number;
	title: string;
	description: string;
	coverImage: string;
	profileImage: string;
	socialNetwork: ChallengeTenderSocial;
	images: string[];
	documents: string[];
	blockDocuments: boolean;
	admin: ProfileModel;
	challenge: ChallengeModel;
	talents: TalentModel[];
	isMine: boolean;
}

class ChallengeTenderModel implements ChallengeTender {
	id: number;
	challengeID: number;
	title: string;
	description: string;
	coverImage: string;
	profileImage: string;
	socialNetwork: ChallengeTenderSocial;
	images: string[];
	documents: string[];
	blockDocuments: boolean;
	admin: ProfileModel = ProfileModel.generateEmpty();
	challenge: ChallengeModel = ChallengeModel.generateEmpty();
	talents: TalentModel[] = [];
	isMine: boolean;

	constructor(
		id: number,
		challengeID: number,
		title: string,
		description: string,
		coverImage: string,
		profileImage: string,
		socialNetwork: ChallengeTenderSocial,
		images: string[],
		documents: string[],
		blockDocuments: boolean,
		isMine: boolean,
	) {
		this.id = id;
		this.challengeID = challengeID;
		this.title = StringUtils.capitalize(title);
		this.description = description;
		this.coverImage = coverImage;
		this.profileImage = profileImage;
		this.socialNetwork = socialNetwork;
		this.images = images;
		this.documents = documents;
		this.blockDocuments = blockDocuments;
		this.isMine = isMine;
	}

	static generateFromAPI(data: any = {}) {
		const socials = getSocial();
		const socialNetworks = {
			[ChallengeTenderSocialType.GOOGLE_MEET]: "",
			[ChallengeTenderSocialType.MICROSOFT_TEAM]: "",
			[ChallengeTenderSocialType.ZOOM]: "",
			[ChallengeTenderSocialType.TRELLO]: "",
		};

		try {
			data.social_networks.forEach((item: string) => {
				if (item.includes(socials.meet.smallRoot)) {
					socialNetworks[ChallengeTenderSocialType.GOOGLE_MEET] = item;
				}

				if (item.includes(socials.team.smallRoot)) {
					socialNetworks[ChallengeTenderSocialType.MICROSOFT_TEAM] = item;
				}

				if (item.includes(socials.zoom.smallRoot)) {
					socialNetworks[ChallengeTenderSocialType.ZOOM] = item;
				}

				if (item.includes(socials.trello.smallRoot)) {
					socialNetworks[ChallengeTenderSocialType.TRELLO] = item;
				}
			});
		} catch (err) {}

		const challengeTenderModel = new ChallengeTenderModel(
			data.id,
			data.challenge.id,
			data.title,
			data.description,
			data.cover_image,
			data.profile_image,
			socialNetworks,
			data.images,
			data.documents,
			data.blocked_document,
			data.isMine,
		);
		const challengeModel = ChallengeModel.generateEmpty();
		challengeModel.id = Number(data.challenge.id);
		challengeModel.slug = data.challenge.slug;

		challengeTenderModel.setChallenge(challengeModel);

		return challengeTenderModel;
	}

	static generateListFromAPI(data: any[]) {
		return Array.isArray(data) ? data.map((item) => ChallengeTenderModel.generateFromAPI(item)) : [];
	}

	static generateEmpty() {
		return new ChallengeTenderModel(
			0,
			0,
			"",
			"",
			"",
			"",
			{
				[ChallengeTenderSocialType.GOOGLE_MEET]: "",
				[ChallengeTenderSocialType.MICROSOFT_TEAM]: "",
				[ChallengeTenderSocialType.ZOOM]: "",
				[ChallengeTenderSocialType.TRELLO]: "",
			},
			[],
			[],
			false,
			false,
		);
	}

	static generateFromObject(challengeTenderObject: ChallengeTender) {
		const challengeTender = new ChallengeTenderModel(
			challengeTenderObject.id,
			challengeTenderObject.challengeID,
			challengeTenderObject.title,
			challengeTenderObject.description,
			challengeTenderObject.coverImage,
			challengeTenderObject.profileImage,
			challengeTenderObject.socialNetwork,
			challengeTenderObject.images,
			challengeTenderObject.documents,
			challengeTenderObject.blockDocuments,
			challengeTenderObject.isMine,
		);

		challengeTender.setAdmin(ProfileModel.generateFromObject(challengeTenderObject.admin));
		challengeTender.setChallenge(ChallengeModel.generateFromObject(challengeTenderObject.challenge));
		challengeTender.setTalents(
			challengeTenderObject.talents
				? challengeTenderObject.talents.map((item) => TalentModel.generateFromObject(item))
				: [],
		);

		return challengeTender;
	}

	setAdmin(newAdmin: ProfileModel) {
		this.admin = newAdmin;
	}

	setChallenge(newChallenge: ChallengeModel) {
		this.challenge = newChallenge;
	}

	setTalents(newTalents: TalentModel[]) {
		this.talents = newTalents;
	}

	getInternalURL = (locale: Locales) => {
		return PageDirectoryUtils.ROUTE_CHALLENGUE_TENDER_WITH_LOCALE(locale) + `/${this.id}`;
	};

	getFormattedCoverImage = (): Image => {
		return {
			src: this.coverImage === DEFAULT_IMAGE || !this.coverImage ? DEFAULT_IMAGE : URL_VINGLET_IMAGE + this.coverImage,
		};
	};

	getFormattedProfileImage = (): Image => {
		return {
			src: this.coverImage === DEFAULT_IMAGE || !this.coverImage ? DEFAULT_IMAGE : URL_VINGLET_IMAGE + this.coverImage,
		};
	};

	getFormatedImages = (): Image[] => {
		return this.images.map((image) => {
			return {
				src: image === DEFAULT_IMAGE || !image ? DEFAULT_IMAGE : URL_VINGLET_IMAGE + image,
			};
		});
	};

	exportToAPIRequest = () => {
		const socialNetworks: string[] = [];
		const documentFiles = this.documents.filter((document) => document !== undefined && document !== null);

		for (const property in this.socialNetwork) {
			if (this.socialNetwork[property as ChallengeTenderSocialType]) {
				socialNetworks.push(this.socialNetwork[property as ChallengeTenderSocialType]);
			}
		}

		return {
			challenge_id: this.challengeID,
			title: this.title,
			description: this.description,
			cover_image: this.coverImage,
			profile_image: this.profileImage,
			social_networks: socialNetworks,
			images: this.images,
			documents: documentFiles,
			block_documents: this.blockDocuments,
		};
	};
}

export default ChallengeTenderModel;
